import { createStore } from "redux";

const initialState = {
  sidebarShow: "responsive",
  auth: false,
  overlaySpinnerShow: false,
  selectedEventId: 0,
  selectEventName: "123",
  accessPermission: {
    accounts: 0,
    admins: 0,
    auditorium_halls: 0,
    auditoriums: 0,
    boardlinks: 0,
    booths: 0,
    careers: 0,
    competitions: 0,
    dashboard: 0,
    event_halls: 0,
    events: 0,
    futureworks: 0,
    futureworks_videos: 0,
    job_industries: 0,
    missions: 0,
    reports: 0,
    schedules: 0,
    speakers: 0,
    surveys: 0,
    users: 0,
    visitors: 0,
    workshop_halls: 0,
    workshops: 0,
  },
};

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case "set":
      return { ...state, ...rest };
    default:
      return state;
  }
};

const store = createStore(changeState);
export default store;
