let tokenName = "emapAccessToken";
let permissionName = "emapAccessPermission";

class AuthService {
  storeToken(token) {
    localStorage.setItem(tokenName, token);
    return token;
  }

  storePermission(access) {
    localStorage.setItem(permissionName, JSON.stringify(access));
    return access;
  }

  getToken() {
    let token = localStorage.getItem(tokenName);
    if (token) {
      return token;
    }
    return null;
  }

  getPermission() {
    let access = localStorage.getItem(permissionName);
    if (access) {
      return (access);
    }
    return null;
  }

  deleteToken() {
    localStorage.removeItem(tokenName);
    return true;
  }

  deletePermission() {
    localStorage.removeItem(permissionName);
    return true;
  }
}

export default new AuthService();
