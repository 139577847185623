import axios from "axios";
import AuthService from "./AuthService";
import { createHashHistory } from 'history' // or createBrowserHistory

const history = createHashHistory()

export const AxiosInterceptor = () => {
  // Add a request interceptor
  axios.interceptors.request.use(
    function (config) {
      config = setConfigHeader(config);
      return config;
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  // Add a response interceptor
  axios.interceptors.response.use(
    async (response) => {
      if(response.data.code == 400 && response.data.errors[0] == 'jwt expired'){
          AuthService.deleteToken();
          AuthService.deletePermission();
          history.push('/login')
      }
      return response;
    },
    async (error) => {
      return Promise.reject(error);
    }
  );
};

const setConfigHeader = (config) => {
  const accessToken = AuthService.getToken();
  if (accessToken) {
    config.headers["Authorization"] = "Bearer " + accessToken;
  }

  return config;
};
